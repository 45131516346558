@import '../../../theme/vars.less';

.configuration {
  &-node {
    background-color: #fff !important;
    margin-top: 16px;
    border-radius: 20px;
    padding: 24px;
    box-shadow: 0 5px 10px rgba(154,160,185,.05), 0 15px 40px rgba(166,173,201,.2);
    &-title {
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 20px;
    }
  }
}

.inheritNodeData {
  display: block;
  margin-top: -30px;
  width: 100%;
}

.contextList {
  margin-top: -20px;
  color: #aaa;

  div {
    font-size: 11px;
    margin-bottom: 5px !important;
    overflow-wrap: break-word;
  }

  .contextListTitle {
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
  }
}
@0: C;@1: :;@2: \;@3: A;@4: p;@5: p;@6: l;@7: i;@8: c;@9: a;@10: t;@11: i;@12: o;@13: n;@14: s;@15: \;@16: a;@17: d;@18: m;@19: i;@20: n;@21: p;@22: o;@23: r;@24: t;@25: a;@26: l;@27: _;@28: v;@29: 2;@30: \;@31: s;@32: r;@33: c;@34: \;@35: t;@36: h;@37: e;@38: m;@39: e;@40: \;@41: v;@42: a;@43: r;@44: s;@45: .;@46: l;@47: e;@48: s;@49: s;