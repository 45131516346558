@import '../../theme/vars.less';

.leftMenu {
  overflow: auto;
  height: 100vh;
  position: fixed;
  left: 0;
  background: @darkGray !important;
  &-logoSkeleton {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 24px 32px;
    box-sizing: border-box;
    > div {
      width: 100%;
      span {
        width: 100% !important;
        height: 72px !important;
      }
    }
  }
  &-logo {
    height: 48px;
    margin: 32px 8px;
    display: flex;
    justify-content: center;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    margin-bottom: 24px;
  }
  ul {
    background: @darkGray !important;
  }
  .menu-skeleton {
    display: flex;
    width: 100%;
    flex-direction: column;
    padding: 24px 16px 0;
    box-sizing: border-box;
    > div {
      margin-bottom: 16px;
      width: 100%;
      > span {
        width: 100% !important;
      }
    }
  }
  &-version {
    color: #fff;
    display: flex;
    font-size: 10px;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 72px;
    width: 100%;
  }
  .ant-layout-sider-trigger {
    background: @gray;
  }
}
@0: C;@1: :;@2: \;@3: A;@4: p;@5: p;@6: l;@7: i;@8: c;@9: a;@10: t;@11: i;@12: o;@13: n;@14: s;@15: \;@16: a;@17: d;@18: m;@19: i;@20: n;@21: p;@22: o;@23: r;@24: t;@25: a;@26: l;@27: _;@28: v;@29: 2;@30: \;@31: s;@32: r;@33: c;@34: \;@35: t;@36: h;@37: e;@38: m;@39: e;@40: \;@41: v;@42: a;@43: r;@44: s;@45: .;@46: l;@47: e;@48: s;@49: s;